body {
  margin: 0;
  background-color: #19181F;
  font-family: 'Poppins', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea, input, button, select { 
  font-family: inherit;
  font-size: inherit;
}

div input, form, form > div, textarea {
  box-sizing: border-box;
}

a {
  color: white;
}
